// @ts-nocheck
import React from "react";
import Helmet from "react-helmet";

import { HasuraConSEO } from "../../../../components/hasuracon2021/HasuraConSEO";
import TopBanner from "../../../../components/hasuracon2021/homepage/TopBanner";
import InterestedSpeakers from "../../../../components/hasuracon2021/homepage/InterestedSpeakers";
import Speakers from "../../../../components/hasuracon2021/homepage/Speakers";
import Talks from "../../../../components/hasuracon2021/homepage/Talks";
import Workshops from "../../../../components/hasuracon2021/homepage/Workshops";
import TopicTables from "../../../../components/hasuracon2021/homepage/TopicTables";
import Sponsor from "../../../../components/hasuracon2021/homepage/Sponsor";
import WelcomeHasuraCon from "../../../../components/hasuracon2021/homepage/WelcomeHasuraCon";
import Layout from "../../../../components/hasuracon2021/Layout";
import heroBg from "../../../../components/hasuracon2021/images/hero-bg.png";
import interestedSpeakerGraphic from "../../../../components/hasuracon2021/images/interested-speaker-graphic.png";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/";

const HasuraCon = ({ location, pageContext }) => {
  const { presentations, presenters } = pageContext;

  return (
    <Layout
      location={location}
      sideBarGraphic={() => (
        <img className="leftInterested" src={interestedSpeakerGraphic} alt="Graphic" />
      )}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern homePattern watchPagePosition"
          loading="lazy"
          src={heroBg}
          alt="Speakers Graphic"
        />
      )}
    >
      <HasuraConSEO canonicalLink={canonicalUrl} />
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <TopBanner
        location={location}
        isWatchPage
        title="The Big Hasura User Conference"
        ctaText="View Recordings → "
        ctaAction="/events/hasura-con-2021/recordings/"
      >
        We’re bringing together users from all over the world for a 2 day celebration of all things
        Hasura! It is a conference about you, and your success. Discover the amazing products
        developers are building, level up your Hasura skills with live, in-depth workshops and hear
        about all the big features and updates we are making to the Hasura GraphQL Engine and Hasura
        Cloud. Enjoy a deep-dive into the tech that makes Hasura...well...Hasura.
      </TopBanner>
      <InterestedSpeakers />
      <Speakers presenters={presenters} featured={true} />
      <Talks presentations={presentations} featured={true} />
      <Workshops presentations={presentations} featured={true} />
      <TopicTables presentations={presentations} featured={true} />
      <Sponsor />
      <WelcomeHasuraCon />
    </Layout>
  );
};

export default HasuraCon;
